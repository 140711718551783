import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root'
})
export class TokenCacheService {
  pkceState: string;
  pkceCodeVerifier: string;
  pkceCodeChallenge: string;

  token1 = null;
  oidcIdToken: string
  accessTokenEcom: string;
  refreshTokenEcom: string;

  domain = "ask-media-user-pool-2";
  region = "ap-northeast-1";
  appClientId = "7hcekc592dve1r3j65fhfaenmb";
  appClientSec = "rbp4jn3q4djelggpk0qhb1n24149nhsteqd28kp1a0mjg782god";
  userPoolId = "ap-northeast-1_FGq733CIc";
    // redirectURI = "http://localhost:4200/callback";
  redirectURI = "https://mahabharatham.hungry-stomach.com/callback";
  authURI = "https://auth.hungry-stomach.com";
  bucket = "media-ask-tky";
  identityPoolId = this.region + ":" +  "aa9d3529-21a4-4b46-ac92-d9838aeec398"
  identityPoolCode = "ap-northeast-1_FGq733CIc"
  identityPoolLongCode = "cognito-idp.ap-northeast-1.amazonaws.com/" + this.identityPoolCode;

  cognitoCredentialsObject;

  constructor(private http: HttpClient) { }

  getCognitoCredentialsObject() {
    return new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.identityPoolId,
      Logins: {
        'cognito-idp.ap-northeast-1.amazonaws.com/ap-northeast-1_FGq733CIc': this.token1.id_token
      }
    });
  }

  configureAwsAccess() {
    AWS.config.credentials = this.getCognitoCredentialsObject();
    AWS.config.region = this.region;
  }
 

  hashToBase64url(str: string): PromiseLike<any> {
    const PROTOCOL = 'SHA-256'
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return crypto.subtle.digest(PROTOCOL, encodedData);
  }

  public loginFlow() {
    sessionStorage.setItem("pckeState", UUID.UUID());
    sessionStorage.setItem("pkceCodeVerifier", UUID.UUID());
    // console.log('pckeState ' + sessionStorage.getItem('pckeState'));
    // console.log('pkceCodeVerifier 1' + sessionStorage.getItem('pkceCodeVerifier'));
    // console.log('pkceCodeChallenge 1' + sessionStorage.getItem('pkceCodeChallenge'));

    let that = this;

    this.hashToBase64url(sessionStorage.getItem('pkceCodeVerifier')).then(function (arrayBuffer) {      

      console.log('value: ' + arrayBuffer);
      const items = new Uint8Array(arrayBuffer)
      const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
      const decodedHash = btoa(stringifiedArrayHash)
      let base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
      // console.log('pkceCodeChallenge 2' + base64URL);
      sessionStorage.setItem("pkceCodeChallenge", base64URL);

      window.location.href = 
      that.authURI +
      // "https://" + that.domain + ".auth." + that.region + ".amazoncognito.com" +
      // "https://auth.hungry-stomach.com" +
        "/oauth2/authorize?" +
        "response_type=code" +
        "&state=" + sessionStorage.getItem('pckeState') +
        "&client_id=" + that.appClientId +
        "&redirect_uri=" + that.redirectURI +
        "&scope=openid" +
        "&code_challenge_method=S256" +
        "&code_challenge=" + sessionStorage.getItem('pkceCodeChallenge');

    });
  }

  acquireTokens(): void {

    let tokenUrl = 
    // "https://" + this.domain + ".auth." + this.region + ".amazoncognito.com" +
    this.authURI +
      "/oauth2/token?" +
      "grant_type=authorization_code" +
      "&client_id=" + this.appClientId +
      "&client_secret=" + this.appClientSec +
      "&code_verifier=" + sessionStorage.getItem('pkceCodeVerifier') +
      "&redirect_uri=" + this.redirectURI +
      "&code=" + sessionStorage.getItem('pkceCode');

    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*'
        })
    };

    let that = this;
    this.http.post<string>(tokenUrl, "", httpOptions)
      .pipe(catchError((err) => {
        console.error(err);
        throw err;
      }
      )).subscribe(data => {
        // console.log('data from auth server ' + JSON.stringify(data));
        this.token1 = JSON.parse(JSON.stringify(data));
        // console.log('id token \n' + this.token1.id_token);
        // console.log('access token \n' + this.token1.access_token);
        // console.log('refresh token \n' + this.token1.refresh_token);
        console.log ('Configuring AWS Access');
        that.configureAwsAccess();
        // this.accessS3AndListFiles();
      });
  }

}
