import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TokenCacheService } from '../services/token-cache.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, 
    private tokenCacheService: TokenCacheService) { }

  ngOnInit(): void {
    let pkceCodeI = this.activatedRoute.snapshot.queryParamMap.get('code');
    if (pkceCodeI) {
      sessionStorage.setItem('pkceCode', pkceCodeI);
      let pckeStateI = this.activatedRoute.snapshot.queryParamMap.get('state');
      // console.log('state in session storage'+sessionStorage.getItem('pckeState'))
      if (pckeStateI == sessionStorage.getItem('pckeState')) {
        console.log('state matched');
        this.tokenCacheService.acquireTokens();
        // this.accessS3AndListFiles();
      }
    } else {
      // this.checkTokenExistence();
    }
  }

}
