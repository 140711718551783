import { Component, OnInit } from '@angular/core';
import { TokenCacheService } from '../services/token-cache.service';
import * as AWS from 'aws-sdk';

@Component({
  selector: 'app-movies',
  templateUrl: './movies.component.html',
  styleUrls: ['./movies.component.css']
})
export class MoviesComponent implements OnInit {

  title = 'movies Browser';
  fileList = [];
  objectPrefix = 'movies/'

  constructor(private tokenCacheService: TokenCacheService) { }

  ngOnInit(): void {
    if (this.tokenCacheService.token1) {
      this.accessS3AndListFiles();
    } else {
      this.tokenCacheService.loginFlow();
    }
  }

  accessS3AndListFiles(): void {
    if (this.tokenCacheService.token1) {
      this.tokenCacheService.configureAwsAccess();
      // console.log('cred ' + JSON.stringify(AWS.config.credentials));

      let marker;
      const params = {
        Bucket: this.tokenCacheService.bucket,
        Prefix: this.objectPrefix,
        Marker: marker
      };
      this.collectFileList(params);
      console.log(this.fileList);
    } else {
      this.tokenCacheService.loginFlow();
    }
  }

  collectFileList(params): void {
    this.fileList = [];
    const s3 = new AWS.S3(AWS.config);
    s3.listObjects(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        // success
        // console.log(JSON.stringify(data));
        data.Contents.forEach(value => {
          this.fileList.push({ 'key': value.Key });
          // console.log(JSON.stringify(value.Key));
        });
        let marker;
        if (data.IsTruncated) {
          if (data.NextMarker) {
            marker = data.NextMarker;
          } else if (data.Contents.length > 0) {
            marker = data.Contents[data.Contents.length - 1].Key;
          }
        }
        const params = {
          Bucket: this.tokenCacheService.bucket,
          Prefix: this.objectPrefix,  
          Marker: marker
        };
        if (data.IsTruncated) {
          this.collectFileList(params);
        }
      }
    });
  }

  onClickMediaItem(objectKey): void {
    console.log('onClickMediaItem clicked ' + objectKey.key);
    const params = {
      Bucket: this.tokenCacheService.bucket, Key: objectKey.key, Expires: 60,
    };
    // console.log('params '+ JSON.stringify(params));
    const s3 = new AWS.S3(AWS.config);
    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        console.log(err);
      } else {
        console.log(url);
        window.open(url, '_blank');
      }
    });
  }


}
